import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
const firebaseConfig = {
  apiKey: "AIzaSyC-zB86BanE-lBpxNwAjNpJrLYCoJwwYSU",
  authDomain: "rakhib420bd.firebaseapp.com",
  projectId: "rakhib420bd",
  storageBucket: "rakhib420bd.appspot.com",
  messagingSenderId: "456393275522",
  appId: "1:456393275522:web:3c4bdd31ea489a65039c9b",
  measurementId: "G-1DBN7RY5B1"
};

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export default db;